<template>
    <div>
      <article>
        <section>
          <div class="city_dialogs">
            <el-dialog
              :title="`Update City in ${region.region_name ? region.region_name : '...' } ( ${region.country ? region.country : '...' })`"
              :visible.sync="isEditCityModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="cityForm"
                  :rules="rules"
                  label-position="top"
                  ref="cityForm"
                  class="demo-cityForm2"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item
                        label="City Name"
                        prop="city_name"
                      >
                        <el-input v-model="cityForm.city_name"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item
                        label="Delivery Fee"
                        prop="delivery_fee"
                      >
                        <el-input v-model="cityForm.delivery_fee"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editCity('cityForm')"
                    > Save City Changes in {{ region.region_name ? region.region_name : '...' }} ( {{ region.country ? region.country : '...' }})</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Bakyala Safaris, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="cityForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        isEditCityModalVisible: false,
        loadingError: false,
        submitting: false,
        cityForm: {
          city_name: "",
          delivery_fee: "",
        },
  
        rules: {
          city_name: [
            {
              required: true,
              message: "City Name is required",
              trigger: "blur",
            },
          ],
          delivery_fee: [
            {
              required: true,
              message: "Delivery Fee is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showCityEditDialog: {
        required: true,
        type: Boolean,
      },
      
      cityData: {
        required: true,
        type: Object,
      },

      region:{
        type: Object,
        required: true
      } 
    },
  
    watch: {
      showCityEditDialog() {
        if (this.showCityEditDialog === true) {
          this.isEditCityModalVisible = true;
          this.cityForm.city_name = this.cityData.city_name;
          this.cityForm.delivery_fee = this.cityData.delivery_fee;
        } else {
          this.isEditCityModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditCityDialog");
      },
  
      async editCity(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.patch(
                `cities/${this.cityData.city_id}`,
                { 
                    city_name: this.cityForm.city_name,
                    delivery_fee: this.cityForm.delivery_fee
                }
              );
              if (
                response.data.success &&
                response.data.message == "CITY_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "City updated successfully",
                  type: "success",
                });
                this.$emit("closeEditCityDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to Update City",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>